.question-block {
    margin-bottom: 2em;
    border: dashed .2em var(--second-color);
    border-radius: 1.2em 1.2em 0 0;
    transition: all .5s ease .5s;

    &.editing {
        border-radius: 1.2em;
        border: dashed .2em var(--first-color);
        transition: all .5s ease;

        .pen svg {
                transform-origin: 0% 90%;
                animation: writer 1.5s linear infinite;
        }
    }

    .question {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: var(--first-color) 2px solid;
        padding: .5em 0;

        & h3 {
            display: block;
            font-size: 2em;
            padding-left: .5em;
            width: fill-available;
        }

        .nav {
            display: flex;
            flex-direction: row;

            & svg {
                fill: var(--first-color);
                width: 100%;
                height: 100%;
            }

            & li {
                margin-right: 1em;
            }

            .pen {
                width: 2em;
                cursor: pointer;
            }

            .trash {
                width: 2em;
                cursor: pointer;
            }
        }

        .num {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            translate: -50% 50%;
            width: 2em;
            height: 2em;
            font-size: 1em;
            font-weight: bold;
            background: var(--second-color);
            color: var(--first-color);
            border-radius: 4em;
            border: var(--first-color) 2px solid;
            text-align: center;
        }
    }

    .answer {
        font-family: Arial, serif;
        font-weight: bold;
        background: var(--light-color);
        color: var(--second-color);
        border-radius: 0 0 1em 1em;
        max-height: 0;
        overflow: hidden;
        transition: all 1s ease;

        &.active {
            min-height: 10px;
            max-height: 200px;
        }

        & p {
            padding: 1em;
        }
    }
}

@keyframes writer {
    0% {transform: rotate(-0deg) translate(0, 0);}
    20% {transform: rotate(-15deg) translate(0.1em, 0);}
    40% {transform: rotate(-5deg) translate(0.2em, 0);}
    60% {transform: rotate(-15deg) translate(0.3em, 0);}
    80% {transform: rotate(-5deg) translate(0.4em, 0);}
    100% {transform: rotate(-0deg) translate(0, 0);}
}