.content {
    display: flex;
    flex-direction: row;
    height: 75%;
    width: 100%;

    .quiz-data {
        width: 50%;
        background: var(--decor-color);
        border-radius: 2em;
        overflow-y: scroll;

        .quiz-block {
            position: relative;
            width: 100%;
            height: 90%;
        }

        .quiz-li {
            width: 100%;
            margin: 2em 0;
        }
    }
}

@media (orientation: portrait) {
    .content {
        flex-direction: column;
        justify-content: space-between;
        height: 80%;

        .quiz-data {
            width: 100%;
            height: 70%;
            padding: 0 2em;
        }
    }
}