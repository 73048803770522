.ui {
    position: relative;
    height: 10%;
    border-top: var(--second-color) 2px solid;

    .quiz-ui, .editor-ui, .data-ui {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 2em;
        background: var(--decor-color);
    }

    .quiz-ui {
        display: none;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &.active {
            display: flex;
        }

        .show {
            font-size: 1.5em;
            font-weight: bold;
            text-decoration: underline;
            color: var(--first-color);
            cursor: pointer;
        }
        .next {
            font-size: 1.5em;
            font-weight: bold;
            text-decoration: underline;
            color: var(--first-color);
            cursor: pointer;
        }
    }
    .editor-ui {
        display: none;
        align-items: center;
        justify-content: flex-end;

        &.active {
            display: flex;
        }
    }
    .data-ui {
        display: none;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        &.active {
            display: flex;
        }

        .close {
            font-size: 1.5em;
            font-weight: bold;
            text-decoration: underline;
            color: var(--first-color);
            cursor: pointer;
        }
    }
}